<template>
  <div class="payment">
    <header>
      <h1>F.A.Q</h1>
    </header>
    <section>
      <div class="container">
        <h2>
          Для удобства наших пользователей на нашей <br />
          платформе есть всевозможные способы оплаты:
        </h2>
        <div class="row">
          <div class="col-xl-4" v-for="(n, i) in data" :key="i">
            <cardBus v-bind="n" />
          </div>
        </div>
      </div>
      <div class="container card_section">
        <div class="row">
          <div class="col-6">
            <div class="cards">
              <h3>Стать партнером</h3>
              <p>
                Лидерство в продажах синхронизирует продуктовый ассортимент.
                Повышение жизненных стандартов детерминирует комплексный анализ
                ситуации.
              </p>
              <router-link to="/" class="btn"> Подробнее</router-link>
            </div>
          </div>
          <div class="col-6">
            <div class="cards">
              <h3>Стать клиентом</h3>
              <p>
                Зарегистрируйтесь на сайте все за несколько минут и получите
                доступ к товарам в рассрочку.
              </p>
              <router-link to="/" class="btn"> Подробнее</router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import creditCart from '../assets/icons/credit-card.svg';
import discount from '../assets/icons/discount.svg';
import star from '../assets/icons/5-star.svg';
import cardBus from '@/components/cardBus.vue';

export default {
  data: () => ({
    data: [
      {
        icon: creditCart,
        heading: 'Автоматическое погашение с карты пользователя',
        title:
          'Ежемесячно, в день оплаты (дата по договору), платформа Uzum Nasiya автоматически списывает деньги с карты пользователя и оповещает пользователя об успешном погашении или остаточной задолженности. Дабы избежать просрочки, просим заблаговременно пополнять лицевой счёт и иметь средства на карте.'
      },
      {
        icon: discount,
        heading: 'Пополнение лицевого счета через карту в личном кабинете',
        lists: [
          `Войти в личный кабинет, нажав на кнопку "Войти"`,
          `В блоке «Пополнение лицевого счета с карты» введите сумму, на которую хотите пополнить ваш лицевой счёт`,
          `Нажмите на кнопку «Пополнить» и платформа Uzum Nasiya автоматически спишет с Вашей карты указанную сумму`
        ]
      },
      {
        icon: star,
        heading: 'Пополнение лицевого счёта через платежные сервисы',
        lists: [
          `Нажать на любой из платежных сервисов: Payme, Click, Apelsin, Myuzcard`,
          `Нажать на кнопку «Оплата» и в графе «Поиск» написать Uzum Nasiya и выбрать наш сервис в поставщиках услуг`,
          `Введите свой персональный ID, сумму оплаты и нажмите на кнопку «Оплатить»`,
          `При успешной оплате данные денежные средства будут направлены на Ваш лицевой счет. Вы можете проверить текущий лицевой счет в личном кабинете платформы Uzum Nasiya`
        ]
      }
    ]
  }),
  components: { cardBus }
};
</script>
<style lang="scss" scoped>
@import '../assets/css/payment.scss';
</style>
